import { useEffect, useState } from "react";
import "../css/Blog.css"
import { db } from "../App";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, doc, getDoc,getDocs, onSnapshot, query, serverTimestamp, where } from "firebase/firestore";
import { post } from "jquery";
import { Button } from "bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar'

const BLOG =()=>{


const Nav = useNavigate()


const [Pdata, setPdata] =  useState([])
const [CAT,setCAT] = useState([])
const [Search,setSearch] = useState('')
const [Reference,SetReference]  = useState([])
const [reslmessage,setreslmessage]= useState('')
const [Postnotfound,setPostnotfound] = useState(false)



const FETCHPosts =async()=>{
const Ref = collection(db,'boltzmannlabs-posts')

await onSnapshot(Ref,(POSTE) => {
    if(POSTE.empty){
        setPostnotfound(true)
    }
    else{
    const postarray= []
    POSTE.forEach(Posts => {
     if(Posts.data().published) {
            postarray.push({id:Posts.id,Author:Posts.data().author ,category:Posts.data().category,title:Posts.data().title,content:Posts.data().content.match(/<img[^>]*>/), date:Posts.data().created_date.toDate()}) 
            }
    
    })

    postarray.sort(function(a,b){
        return b.date-a.date
        })
    setPdata(postarray)
    SetReference(postarray)
    }
     })
}

const FetchCategories = async ()=>{

    const Ref = collection(db,'boltzmannlabs-categories')
    const CATARR =[]
    const CATS = await getDocs(Ref)
    CATS.forEach((data)=>{
       CATARR.push(data.data().title)
    })
  setCAT(CATARR)
}


const SearchFun =async(e)=>{
e.preventDefault()
const data = Reference
const Farray = await data.filter((data)=>{ return data.title.toLowerCase().includes(Search)})
    if (Farray.length==0){
        setPostnotfound(true)
    }
setPdata(Farray)


setreslmessage(Search)
}
const clearSearch=()=>{
    setSearch('')
    setPdata(Reference)
    setreslmessage('')
}



console.log(Pdata)
useEffect(()=>{
    document.title = 'Boltzmann | Blog'
    setTimeout(() => {
      FETCHPosts()
    }, 200)
    FetchCategories()
    window.scrollTo(0, 0)
},[])
  
    return (
        
        <div style={{marginTop:'20px'}}>
    
    <section class="blog_area sec_pad">
    
            <div class="container">
            {
                (reslmessage!='' ) && 
                <div style={{marginBottom:'10px'}}>
                <span style={{color:'#501f84', fontWeight:600}}>Search results for {reslmessage}</span>
                <button onClick={clearSearch} style={{background:'none',border:'none'}}><i class="fa fa-times" aria-hidden="true"/></button>
                </div>
            }
            

                <div class="row">
                    <div class="col-lg-8">
                    {
                    Pdata.length==0 ?
                     Postnotfound ?
                    <p style={{fontSize:'20px', color:'red',padding:'10px'}}> No posts Found</p> 
                    : 
                    
                    <div style={{width: '100%',height: '100%', minheigh:'50vh',textAlign: 'center'}}><span class="loader" style={{position:'relative',top:'50%'}}></span></div>
                    :
                        <div class="row" id="blog_masonry">
                            
                            {
                            
                                Pdata.map((data,index)=>{
                                    
                                return(
                              <div class="col-lg-6 col-sm-6" key={index}>
                                <div class="blog_grid_item mb-30" >
                                
                                    <div class="blog_img">
                                    {data.content!=null ?
                                      <div style={{minHeight:'200px'}}  dangerouslySetInnerHTML={{ __html: data.content }} />
                                      :<img style={{minHeight:'200px'}} src={require('../newimages/blank.jpg')} />
                                    }
                                    </div>
                                    <div class="blog_content">
                                        <div class="entry_post_info">
                                            By: <span>{data.Author!=null ? data.Author: 'Admin'} /</span>
                                            <span>{data.date.toString().slice(4,15)}</span>
                                        </div>
                                        <a onClick={(e)=>{e.preventDefault();Nav(`/POST/${data.id}`)}}>
                                            <h5 class="f_p f_size_20 f_500 t_color mb_20">{data.title}
                                            </h5>
                                        </a>
                                    </div>
                                
                                </div>
                                 </div>
                                 ) }
                                )}
                                
                           
                            {/* <ul class="col-lg-12 list-unstyled page-numbers shop_page_number text-left mt_30">
                                <li><span aria-current="page" class="page-numbers current">1</span></li>
                                <li><a class="page-numbers" href="#">2</a></li>
                                <li><a class="next page-numbers" href="#"><i class="ti-arrow-right"></i></a></li>
                            </ul> */}
                        </div>
                    }
                    </div>
                    <div class="col-lg-4">
                        <div class="blog-sidebar">
                            <div class="widget sidebar_widget widget_search">
                                <div style={{borderStyle:'solid', display:'flex',justifyContent: 'space-between', borderRadius:'10px'}}>
                                    <input style={{border:'none',background:'none',outline: 'none',width: '90%', paddingLeft:'10px'}} onChange={(e)=>{setSearch(e.target.value.toLowerCase())}} value={Search}></input>
                                    <button style={Search==''?{display:'none'}:{border:'none',background:'none'}}
                                    onClick={clearSearch} ><i class="fa fa-times" aria-hidden="true"></i></button>
                                    <button style={{border:'none',background:'none'}} onClick={SearchFun}>
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="widget sidebar_widget widget_categorie mt_60">
                                <div class="widget_title">
                                    <h3 class="f_p f_size_20 t_color3">Categories</h3>
                                    <div class="border_bottom"></div>
                                </div>
                                <ul class="list-unstyled">
                                {CAT.map((data,index)=>{
                                    return(
                                    <li key={index}> <button style={{background:'none',border:'none'}} onClick={(e)=>{
                                        e.preventDefault()
                                        Nav(`/Blog/${data}`)
                                    }}><span>{data}</span></button> </li>)
                                })}  
                                </ul>
                            </div>
                            {/* <div class="widget sidebar_widget widget_tag_cloud mt_60">
                                <div class="widget_title">
                                    <h3 class="f_p f_size_20 t_color3">Tags</h3>
                                    <div class="border_bottom"></div>
                                </div>
                                <div class="post-tags">
                                    <a href="#">SaasLand</a>
                                    <a href="#">Web Design</a>
                                    <a href="#">Saas</a>
                                    <a href="#">Cooling System</a>
                                    <a href="#">Corporate</a>
                                    <a href="#">Software</a>
                                    <a href="#">Landing</a>
                                    <a href="#">Wheels</a>
                                </div> 
                            </div> */}
                        </div>
                    </div>

                </div>
            </div>
        </section>
        </div>
      );


    



}
export default BLOG