import {useState,useEffect} from 'react'
import '../css/custom.css'
import '../css/honey.css'
import '../css/page.min.css'
import '../css/style.css'
import '../css/timeline.css'
import '../css/timeline2.css'
import './productextrastyles.css'
import $ from "jquery"
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/esm/CarouselItem'












const Product = ()=>{




useEffect(()=>{
    document.title = 'Boltzmann | Product'
    window.scrollTo(0, 0)
},[])


return(
    <>
         <div style={{ height: '30px', width: '100%', backgroundColor: 'white' }}></div>

{/* <!-- Main Content --> */}
<main class="main-content" id="products">
    <section id="section-features" class="section">

        <div class="container" id="products">

            <h3 style={{color: 'white'}}>Our Products</h3>
            <div class="row">
                <div class="col-sm-7">
                    <h4 style={{color: '#fff', fontSize: '30px',lineHeight: '130%',fontWeight: '600'}}>BoltChem<sup
                           style={{ color: 'hsla(0, 0%, 100%, 0.5)', fontSize: 'small' }}>TM</sup></h4>
                    <p class="aboutp" style={{ color: 'yellow', marginLeft: '2%', fontWeight: 500 }}>An AI Chemistry Studio for drug design.</p>
                    <p style={{ color: 'white', fontWeight: 600, marginLeft: '2%' }}>Discover novel small molecules with just a few clicks away.<br/>
                    Exploration of chemical space and property prediction of small molecules automated with BoltChem.<br/>
                    </p>
                    {/* <!-- <a href="https://boltchem.com/"
      style="text-decoration: none;border-radius: 5px;text-decoration: underline;font-size: 20px;font-weight: bold;"
      target="blank">Know
      more</a> --> */}
                    <br/>

                    <div class="container-fluid py-2">
                        <div class="row align-items-center" style={{marginRight:"10%"}}>
                            <div class="col">
                                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                <Carousel interval={3000} controls={false}  indicators={false}>
                               <Carousel.Item>
                                            <div class="row align-items-center">
                                                <div class="col-md py-2">
                                                    <h4 style={{color: 'yellow'}}>QSAR like models</h4>
                                                    <p class="p-1" style={{color: 'white'}}>Build QSAR like property
                                                        models to predict bioactivity,
                                                        physicochemical or
                                                        pharmacokinetic properties using Machine Learning and Deep
                                                        Learning. These models will be
                                                        used to define the chemical space and hypothesis.</p>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Machine
                                                                Learning</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Deep
                                                                Learning</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Active
                                                                learning</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button class="btn btn-outline  btn-sm boltchem-button">
                                                                Transfer
                                                                learning
                                                            </button>
                                                        </div>
                                                    </div>




                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <h4 style={{color: "yellow"}}>Molecule design</h4>
                                                    <p class="p-1"> Molecules designed to fit the desired chemical
                                                        space using AI. All the
                                                        traditional flows
                                                        reimagined with an AI first approach to cater the current
                                                        day technology needs in drug
                                                        design. Best in class generative algorithms used to design
                                                        efficient molecules. </p>
                                                    <div class="row">
                                                        <div class="col-sm-6">

                                                            <button class="btn btn-outline  btn-sm boltchem-button">
                                                                Edit based
                                                                generation
                                                            </button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button class="btn btn-outline  btn-sm boltchem-button">
                                                                Random
                                                                generation
                                                            </button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button class="btn btn-outline  btn-sm boltchem-button">
                                                                Fragment
                                                                based
                                                                generation
                                                            </button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button class="btn btn-outline  btn-sm boltchem-button">
                                                                Rationale
                                                                based generation
                                                            </button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button class="btn btn-outline  btn-sm boltchem-button">
                                                                Structure based
                                                                generation
                                                            </button>
                                                        </div>
                                                    </div>





                                                </div>
                                            </div>
                                        </Carousel.Item>
                                        
                                
                                        <Carousel.Item>
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <h4 style={{color: "yellow"}}>Screening</h4>
                                                    <p class="p-1">Generated molecules or commercially available
                                                        molecules are screened
                                                        virtually as per the
                                                        hypothesis with multiple layers of screens. Clusterization
                                                        to deal with big data.</p>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline-primary  btn-sm boltchem-button">AI/ML
                                                                Screening</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline-primary  btn-sm boltchem-button">Novelty</button>

                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline-primary  btn-sm boltchem-button">Desired
                                                                chemical properties</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline-primary  btn-sm boltchem-button">Medicinal
                                                                Chemistry filters</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </Carousel.Item>
                                        <Carousel.Item>
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <h4 style={{color: "yellow"}}>Molecule Optimization</h4>
                                                    <p class="p-1">Drug-like molecules optimized with AI flows to
                                                        improve efficacy, affinity,
                                                        and
                                                        selectivity. Eliminate toxicity and minimize side effects.
                                                        Feedback from the lab
                                                        experiments is considered to improve the model and optimize
                                                        the molecules better. </p>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">ADME
                                                                Tox prediction</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">ADME
                                                                Tox prediction</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Edit
                                                                based
                                                                optimization</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Selectivity
                                                                assessment</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Off
                                                                target
                                                                prediction and analysis</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </Carousel.Item>
                                            </Carousel>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-sm-5" style={{marginTop:'5%'}}><img src={require("../newimages/Website_designs.gif")} alt=""
                        style={{height: '400px',width:'500px'}}/></div>
            </div>
        </div>
    </section>
    {/* <!--our products--> */}
    <section id="section-features" class="section" style={{backgroundColor: "white"}}>
        <div class="container">
            <div class="row">
                <div class="row" style={{marginTop:'4%'}}>
                    <div class="col-sm-5"><img src={require("../gifs/Website_designs_1.gif")} alt=""
                            style={{height: '400px',width: '500px'}}/></div>
                    <div class="col-sm-7">
                        <h4 class="product">Rebolt</h4>
                        <p class="aboutp psub-head">Next-Generation AI Synthesis
                            planning tool.</p>
                        <p class="psub-p">Next generation synthesis tool for
                            chemists.<br />
                            Plan and Design reaction pathways within a few minutes by just a few clicks.<br />
                            Synthesis made Effortless, Economical and Expeditious.<br /></p>

                        <div class="container-fluid py-2">
                            <div class="row align-items-center" style={{marginRight:"10%"}}>
                                <div class="col">
                                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                        <Carousel  interval={2000} controls={false} indicators={false}>
                                         <Carousel.Item>
                                                <div class="row align-items-center">
                                                    <div class="col-md py-2">
                                                        <h4 class="psub-head2">Retrosynthetic Analysis</h4>
                                                        <p class="psub-p2">Prediction of synthetic routes by
                                                            breaking
                                                            down complex molecules into
                                                            simpler
                                                            commercially available reactants.</p>
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <button
                                                                    class="btn btn-outline  btn-sm section-button">Template-based</button>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <button
                                                                    class="btn btn-outline  btn-sm section-button">Template-free</button>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <button
                                                                    class="btn btn-outline  btn-sm section-button">Combined
                                                                    synthesis</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <h4 class="psub-head2">Chemical Catalogue</h4>
                                                        <p class="psub-p2">Find the links of simpler commercially
                                                            available starting materials in
                                                            the
                                                            synthetic
                                                            routes with purchases facilitated.</p>
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <button
                                                                    class="btn btn-outline  btn-sm section-button">Chemical
                                                                    databases</button>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <button
                                                                    class="btn btn-outline  btn-sm section-button">Commercial
                                                                    Vendors</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <h4 class="psub-head2">Synthesis</h4>
                                                        <p class="psub-p2">Generate analogues easily with the
                                                            synthetic
                                                            paths.Single-step synthesis
                                                            using Forward
                                                            reaction</p>
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <button
                                                                    class="btn btn-outline  btn-sm section-button">Analogue
                                                                    Generation</button>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <button
                                                                    class="btn btn-outline  btn-sm section-button">Forward
                                                                    reaction</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="section-features" class="section">
        <div class="container">
            <div class="row">
                <div class="col-sm-7">
                    <h4 class="product" style={{color: "white"}}>BoltBio</h4>
                    <p class="psub-p" style={{color:"white"}}>
                        Treatment of rare and common diseases made simple. <br/>
                        Identify novel targets with notable speed and accuracy.<br/>
                        Pathway analysis, Network biology, potential target identification, Interaction
                        predictions and more in
                        BoltBio
                    </p>
                    <div class="container-fluid py-2">
                        <div class="row align-items-center" style={{marginRight:"10%"}}>

                            <div class="col">
                                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                    <Carousel  controls={false} indicators={false}>
                                    <Carousel.Item>
                                            <div class="row align-items-center">
                                                <div class="col-md py-2">
                                                    <h4 style={{color: "yellow"}}>Multi-Omics Analysis</h4>
                                                    <p class="psub-p2" style={{color: "white"}}>Pre Process and analyze the big data of
                                                        the human biology to draw
                                                        insights.</p>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Transcriptomics</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Epigenomics</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Genomics</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Proteomics</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <h4 class="psub-head2" style={{color:"yellow"}}>Systems & Network Biology</h4>
                                                    <p class="psub-p2" style={{color: "white"}}>Use existing or new interaction
                                                        information and gene expressions to
                                                        create a knowledge
                                                        graph.</p>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Gene
                                                                Expressions</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline  btn-sm boltchem-button">Protein
                                                                Interactions</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline btn-sm boltchem-button">Pathway
                                                                analysis</button>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <h4 class="psub-head2" style={{color: "yellow"}}>
                                                        Target Identification</h4>
                                                    <p class="psub-p2" style={{color:"white"}}>Analyze Graph Neural Networks to
                                                        identify, and prioritize biomarkers.
                                                    </p>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline btn-sm boltchem-button">Network
                                                                Analysis</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline btn-sm boltchem-button">Biomarker
                                                                identification</button>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button
                                                                class="btn btn-outline btn-sm boltchem-button">Target
                                                                prioritization</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-sm-5"><img src={require('../gifs/ezgif.com-gif-maker.gif')} alt=""
                        style={{height: "500px",width: "650px",marginTop: 0}}/></div>
            </div>
        </div>
    </section>
    <section id="section-features" class="section" style={{backgroundColor: "white"}}>
        <div class="container">
            <div class="row">
                <div class="col-sm-5"><img src={require("../gifs/boltpro.gif")} alt="" style={{height: '400px',width: '450px'}}/></div>
                <div class="col-sm-7">
                    <h4 class="product">BoltPro</h4>
                    <p class="psub-p2">Potential targets that cannot be hit
                        by small molecules are targeted with peptides or biologics.</p>
                    <p class="psub-p2">BoltPro is a studio to engineer proteins, peptide drugs, and
                        antibodies.<br/> It helps understand the effects of mutations on molecular properties,
                        multi-objective
                        antibody
                        design,Peptide design, protein folding, and optimization of latency.</p>
                    <div class="row">
                        <div class="col-sm-6">
                            <button class="btn btn-outline  btn-sm section-button">Mutations</button>
                        </div>
                        <div class="col-sm-6">
                            <button class="btn btn-outline  btn-sm section-button">Peptide design</button>
                        </div>
                        <div class="col-sm-6">
                            <button class="btn btn-outline  btn-sm section-button">Optimization</button>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </section>

</main>





    </>
)
}
export default Product