import {useState,useEffect} from 'react'
import '../css/custom.css'
import '../css/page.min.css'
import '../css/style.css'
import '../css/contact.css'












const Contact = ()=>{

useEffect(()=>{
    document.title = 'Boltzmann | Contact'
    window.scrollTo(0, 0)
})




return(

    <main class="main-content">



    {/* <!--collaborators start--> */}

    <section id="section-features" class="section section-contact">

        <div class="container">

            <div class="container">



                <h2 class="text-center" style={{color:"#501f84",marginTop:"5%"}}>Contact Us</h2>



                <div class="row gap-y mt-8">

                    {/* <!-- <form class="col-lg-6" id="ContactForm" method="POST">



                        <div class="form-row">

                            <div class="form-group col-md-6">

                                <input class="form-control form-control-lg" type="text" name="name"

                                    placeholder="Full name">

                            </div>



                            <div class="form-group col-md-6">

                                <input class="form-control form-control-lg" type="text" name="company"

                                    placeholder="Company Name">

                            </div>



                            <div class="form-group col-md-6">

                                <input class="form-control form-control-lg" type="email" name="email"

                                    placeholder="Email">

                            </div>



                            <div class="form-group col-md-6">

                                <input class="form-control form-control-lg" type="text" name="phone"

                                    placeholder="Phone">

                            </div>

                        </div>



                        <div class="form-group">

                            <textarea class="form-control form-control-lg" rows="4"

                                placeholder="What do you have in mind?" name="message"></textarea>

                        </div> --> */}

                    {/* <!--<div class="form-group">

                            <div class="g-recaptcha" data-sitekey="6LcN7QocAAAAAJuD4Ah0PT58slijHC9Uvs8N4kC6"></div>

                        </div>-->


                    <!-- 
                        <button class="btn btn-lg btn-info" type="submit">Send message</button>

-->

                    <!-- </form> --> */}





                    <div class="col-md-12 ml-auto contact-container">

                        <div class="h-full" style={{color: "white",fontFamily:'Dosis, sans-serif'}}>

                            <p style={{textAlign: "center",fontWeight:"bold",fontSize: "30px",fontFamily: "monospace"}}>
                                We'd Love to Hear from You</p>

                            <p style={{textAlign: "center",fontWeight:"bold"}}>We are open from 9:30 am — 5:30 pm week
                                days.</p> 

                            <p style={{textAlign: "center"}}>Walk into our office at B-305,
                                Block II, KSSIDC Complex,<br/>
                                Electronic City Phase I,<br/>
                                Bengaluru - 560100</p>


                            <hr class="w-20 my-6" style={{border:"1px solid #501f84"}}/>
                            <div style={{textAlign: "center"}}>
                                <i class="fa fa-envelope mr-4" >
                                    <span style={{marginLeft:'5px'}}>contact@boltzmann.co</span></i>
                                <i class="fa fa-phone ml-4"> +91
                                    9498866488</i>
                            </div>
                            <div style={{textAlign: "center"}} class="mt-5">
                                <button class="btn btn-lg mail-button" type="button"  onClick={()=>{
                                                                                      window.location.assign("mailto:contact@boltzmann.co");
                                                                                             }}><i
                                        class="fa fa-envelope"> Mail us</i></button>
                            </div>
                        </div>

                    </div>

                </div>



            </div>

        </div>

    </section>

    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.12853333089004!2d77.66196000000002!3d12.839623400000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6da491b71541%3A0xe1ed4f5e5f3873fe!2sBoltzmann%20Labs!5e0!3m2!1sen!2sin!4v1638361421279!5m2!1sen!2sin"
        width="100%" height="450" style={{border:0}} loading="lazy"></iframe>

</main>


)
}

export default  Contact