import {useState,useEffect} from 'react'
import '../css/custom.css'
import '../css/honey.css'
import '../css/page.min.css'
import '../css/style.css'
import '../css/timeline.css'
import '../css/timeline2.css'













const About = ()=>{





 



  const aboutpStyles= {
    color: 'gold',
    fontWeight: 'normal',

    fontSize: '20px',

    fontFamily: 'Arial, Helvetica, sans-serif',
    fontWeight:'400',
    lineHeight: '130%'
  }

 const sectionaboutus= {
    backgroundColor: 'white'
  }

useEffect(()=>{
    document.title = 'Boltzmann | About'
    window.scrollTo(0, 0)
})


return(
<div >
<main class="main-content" id="#about">

    <section id="section-features" class="section section-aboutus" >

      <div class="container">

        <h5
          style={{textAlign: 'center', color: 'white',fontFamily: 'Inter,sans-serif',fontWeight: 'bold', marginTop: '7%',fontSize: '30px'}}>

          BOLTZMANN LABS </h5>

        <span
          style={{fontSize: '20px',display: 'block',textAlign: 'center', marginTop: '0%',color: 'gold',fontWeight: 'bold',marginBottom: '4%'}}>Accelerating
          sciences research

        </span>


        <div>
          <em>
            <p
              style={{textAlign: 'center',marginBottom: '36px',fontFamily: 'Inter,sans-serif',color: '#fff',fontSize: '28px',lineHeight: '110%',fontWeight: 700,letterSpacing: 0,color: 'white'}}>
              <span style={{fontSize:'40px',color: 'white',textTransform: 'uppercase'}}>"</span><span
                style={{color: 'white'}}>Now is the time</span> to replace the conventional approaches of drug discovery
              with forefront technologies to

              bring about novel drug molecules with greater precision.<span
                style={{fontSize:'40px',color: 'white'}}>"</span></p>
          </em>
        </div>

        {/* <!-- <div class="row gap-y align-items-center">

          <div class="col-md-6 mr-auto">

            <p class="aboutp" style="color: white;text-align:justify;border-left:2px solid #b476ff;padding: 15px;">

              Scientists have been spending long and tedious hours in laboratories trying to create drug-like molecules.

              <br>

              <span style="color: hsla(0,0%,100%,.5);font-weight: 500;">The world needs new alternatives that can help researchers work faster and

                easier.</span><br>

              Now is the time to replace the conventional approaches of drug discovery with forefront technologies to

              bring about novel drug molecules with greater precision.

            </p>

          </div>



          <div class="col-md-5">

            <img src="newimages/vision.png" alt="Our Vision">

          </div>

        </div> --> */}











        <div class="row gap-y align-items-center">

          <div class="col-md-6 ml-auto">
            <h4  style={{color: 'white', fontSize: '25px',fontWeight: '600',textDecoration: 'none'}}>Vision</h4>

            <p class="aboutp" style={aboutpStyles}>
              Boltzmann, with a vision of building AI tools for speeding up the process of

              sciences research, strives to empower the scientists in the pharma industry by giving them the ability to

              use AI hassle-free. <br/>
            </p>
            <h4 style={{color: 'white', fontSize: '25px',fontWeight: '600',textDecoration: 'none'}}>Mission</h4>
            <p class="aboutp" style={aboutpStyles}>
              Applying cutting-edge technologies like Artificial Intelligence, Deep Learning Networks, Machine learning

              algorithms, and more using data-driven approaches to redesign the conventional drug design practices.

            </p>
            <p class="aboutp" style={aboutpStyles}>Our products give a
              new ray of hope to traditional drug discovery.</p>

          </div>



          <div class="col-md-5 order-md-first">

            <img src={require("../newimages/6-2-innovation-png-image.png")} alt="Goal"/>

          </div>

        </div>





      </div>

    </section>
<section id="team" class="our-webcoderskull padding-lg" style={{backgroundColor:'white'}}>
    <div class="container">
      <div class="bic-section-title" >
        <h2 style={{textAlign: "center",color:"#501F84", marginBottom:'30px',fontWeight:'bold'}}>Our Team</h2>

        <h3 style={{textAlign: "center",color:"black", fontSize:'24px',fontWeight:600,fontFamily: 'Inter,sans-serif',fontStyle:'italic'}} >Team Boltzmann is young and enthusiastic. It is a perfect blend of expertise from diverse domains. The team coordinates to produce miraculous software using cutting-edge technologies and data-driven approaches to revolutionize Drug Discovery.</h3>
        <h4 style={{textAlign: "center",color:"black",fontWeight:500,textDecoration:'none', marginBottom:'80px',fontStyle:'italic'}}>Teamwork, Responsibility, Commitment, Quality, and Ethics are what you can see at Boltzmann.</h4>
      </div>
      <ul class="row" >
        <li class="col-12 col-md-6 col-lg-4">
          <div class="cnt-block equal-hight" style={{height: "320px"}}>
            <figure><img src={require("../newimages/team/sar.png")} class="img-responsive" alt="sarath kolli"/></figure>
            <h3>Sarath kolli</h3>
            <p>Chief Executive Officer</p>
            <a class="social-linkedin" href="https://www.linkedin.com/in/kolli-sarath-640241b/"><i
              class="fa fa-linkedin"></i></a>
          </div>
        </li>
        
        <li class="col-12 col-md-6 col-lg-4">
          <div class="cnt-block equal-hight" style={{height: "320px"}}>
            <figure><img src={require("../newimages/team/pari.png")} class="img-responsive" alt="Paritosh Prashar"/></figure>
            <h3>Dr. Paritosh Prashar</h3>
            <p>Co-Founder</p>

            <a class="social-linkedin" href="https://www.linkedin.com/in/paritosh-prashar-04a366163/"><i
              class="fa fa-linkedin"></i></a>
          </div>
        </li>
        <li class="col-12 col-md-6 col-lg-4">
          <div class="cnt-block equal-hight" style={{height: "320px"}}>
            <figure><img src={require("../newimages/team/Prash_Blazer.png")} class="img-responsive" alt=""/></figure>
            <h3>prashamsa</h3>
            <p>Product Manager</p>

            <a class="social-linkedin" href="https://www.linkedin.com/in/prajwala-prashamsa-266661102/"><i
              class="fa fa-linkedin"></i></a>

          </div>
        </li>
        <li class="col-12 col-md-6 col-lg-4">
          <div class="cnt-block equal-hight" style={{height: "320px"}}>
            <figure><img src={require("../newimages/team/NIRISHA_2.jpg")} class="img-responsive" alt="nirisha"/></figure>
            <h3>nirisha</h3>
            <p>Product Manager</p>

            <a class="social-linkedin" href="https://www.linkedin.com/in/nirisha-potharaju-3266aa1b6/"><i
              class="fa fa-linkedin"></i></a>
          </div>
        </li>
        
        <li class="col-12 col-md-6 col-lg-4">
          <div class="cnt-block equal-hight" style={{height: "320px"}}>
            <figure><img src={require("../newimages/team/yakoob_black.png")} class="img-responsive" alt="Yakoob"/></figure>
            <h3>yakoob</h3>
            <p>AI Engineer</p>
            <a class="social-linkedin" href="https://www.linkedin.com/in/yakoob-baba-shaik/"><i
              class="fa fa-linkedin"></i></a>
          </div>
        </li>
        <li class="col-12 col-md-6 col-lg-4">
          <div class="cnt-block equal-hight" style={{height: "320px"}}>
            <figure><img src={require("../newimages/team/Sainadh.jpeg")} class="img-responsive" alt="saninadh"/></figure>
            <h3>DR. V S SAINADH NIMMAGADDA</h3>
            <p>Scientific Outreach Officer</p>
            {/* <!-- <a class="social-linkedin" href="https://www.linkedin.com/in/devach/"><i
              class="fa fa-linkedin"></i></a> --> */}
          </div>
        </li>
      </ul>
    </div>
    
    <p style={{textAlign: 'center',color:"#501F84",fontWeight:'bold', marginTop:'20px',fontFamily: 'Dosis, sans-serif',fontSize: '1.5rem'}} >Intrested in joining us ? please <a  href='mailto:hr@boltzmann.co'>contact us</a> </p>
   
  </section>
  {/* <!-- Main Content --> */}

  



    {/* <!-- <section class="section text-center" id="contact" style="background-color: #501f84;">

      <div class="container">



        <div class="row">

          <div class="col-md-6 mx-auto">



            <h3 class="display-3 fw-400 white"><strong style="color: white;">Let's Work Together</strong></h3>

            <p class="lead-2 " style="color: white;">Want to collaborate with Boltzmann or try out BoltChem?</p>

            <br>

            <a class="btn btn-lg btn-round btn-light px-7" href="contact.html">Send a Message</a><br><span

              style="color: white;">Or</span><br>

            <p class="lead-2 " style="color: white;">Call us: +91 8747926149 </p>







          </div>

        </div>



      </div>

    </section> --> */}



  </main>
  {/* <div class="work-process">

    <div class="container">
      <div class="row">
        <div class="col-lg-4 mb-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">10% success rate </h5>
              <p class="card-text">7 hits in 3 new chemical spaces
                identified with one molecule
                100% selective.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">3-4 weeks </h5>
              <p class="card-text">Per cycle or iteration to come up
                with an initial set of molecules</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">1 cycle</h5>
              <p class="card-text">Lead-like molecules in the first
                cycle</p>
            </div>
          </div>
        </div>
      </div>






    </div>
    <!-- /.container -->
  </div> */}
  {/* <!-- Main Content --> */}
  <main class="main-content">
    {/* <!--our products--> */}

    {/* <!--collaborators start--> */}
    <section id="section-features" class="section" style={{marginTop: '0px'}}>
      <div class="container">
        <img src={require("../newimages/team/collab.png")} alt="collaboration" style={{height: '300px',width: "300px",display: "block",margin: "auto"}}/>
        <h3 style={{color: 'white',fontWeight: 700,textAlign: 'center'}}>Open Source</h3>
        <p style={{textAlign: 'center', color: 'yellow',fontWeight: 600}} class="aboutp">We contribute to open-source communities Bayes Labs & ChemBioAI.</p>
        <p style={{color: 'white',fontWeight: 600,textAlign: 'center'}}>Bayes Labs is a community that coordinates from remote locations to produce quality work in the fields of AI and Drug Discovery research. It has developed multiple packages that will aid in the sciences research</p>
        {/* <!-- <p style="color: hsla(0,0%,100%,.5);font-weight: 600;margin-left: 20%;"> </p> --> */}
        <div style={{display:'flex',justifyContent: 'space-around'}}>
        <p style={{color: 'white',fontWeight: 600,textAlign: 'center'}}>Explore <a style={{color:'yellow'}} target="_blank"
            href="https://bayeslabs.co/">Bayes Labs</a> now.</p>
         <p style={{color: 'white',fontWeight: 600,textAlign: 'center'}}>Explore <a style={{color:'yellow'}} target="_blank"
            href="https://discord.gg/XjKKvsHH">ChemBioAI</a> now.</p>
        {/* <!-- <a href="https://forms.gle/4TLaAtN5qBoe9XWn8">Contact us</a> --> */}
        </div>
      </div>
    </section>
  </main>


</div>
)
}


export default About