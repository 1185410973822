
import Homepage from "./pages/hompage";
import About from "./pages/about";
import Product from "./pages/Product";
import Partnership from "./pages/partnership";
import Contact from "./pages/contact"
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import NavigationBar from "./pages/naviBar";
import Footer from "./pages/footer";
import BLOG from "./pages/Blog";
import DetailedPost from "./pages/detailedPost";
import CategoryBlog from "./pages/categoryBlog";
import {useNavigate, BrowserRouter, Route, Routes} from "react-router-dom"
import { lazy } from "react";



// firebase imports 
import { initializeApp } from "@firebase/app";
import { getAnalytics } from "@firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";






const firebaseConfig = {
  apiKey: "AIzaSyCN4aozpjya1-IPcXW60t1NxLu7bUUfmVU",
  authDomain: "boltzmann-admin.firebaseapp.com",
  projectId: "boltzmann-admin",
  storageBucket: "boltzmann-admin.appspot.com",
  messagingSenderId: "1023878106371",
  appId: "1:1023878106371:web:bbe53083ddf3a1d1c1ad36"
};








const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);




function App() {




  








  
  const Routing = {
    path: '/',
    element: <Homepage />,
    children: [
        {
            path: '/',
            element: <Homepage/>
        },
        {
            path: '/Products',
            element: <Product />
        },
        {
            path: '/About',
            element: <About />
        },
        {
            path: '/Blog',
            element: < BLOG/>
        },
        {
            path: '/Partnership',
            element: <Partnership/>
        },
        {
          path: '/Terms',
          element: <Terms/>
      },
      {
        path: '/Privacy_policy',
        element: <Privacy/>
    },
    {
      path: '/Contact',
      element: <Contact/>
  },
  {
    path:`/POST/:blogid`,
    element:<DetailedPost/>
  },
  {
    path:`/Blog/:category`,
    element:<CategoryBlog/>
  }
  


    ]
  };

















  return (
    <div className="App">
      <header className="App-header">
     
      <BrowserRouter>
      <NavigationBar styles={{marginBottom:"10px"}}/>
      <Routes>
{
  Routing.children.map((data, index)=>{
    return(
      <Route exact key={index} path={data.path} element={data.element}/>
    )
  })
}
</Routes>
<Footer/>
      </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
export {db}
