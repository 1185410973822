
import { db } from "../App"
import {useState, useEffect} from "react"
import { useParams, useNavigate } from "react-router-dom"
import { addDoc, collection, doc, getDoc,getDocs, onSnapshot, query, serverTimestamp, where } from "firebase/firestore";
import "../css/Blog.css"





const DetailedPost =()=>{


const Nav = useNavigate()    

const [Pdata,setPadata] = useState('')
const [CAT,setCAT]=useState([])

const {blogid} = useParams()


const PostFetch=async()=>{
const ref =doc(db,'boltzmannlabs-posts',blogid)

await getDoc(ref).then((snapshot) => {
            
   setPadata(snapshot.data());
}).catch((err) => console.log(err));



}

const FetchCategories = async ()=>{

    const Ref = collection(db,'boltzmannlabs-categories')
    const CATARR =[]
    const CATS = await getDocs(Ref)
    CATS.forEach((data)=>{
       CATARR.push(data.data().title)
    })
  setCAT(CATARR)
}


useEffect(()=>{
    PostFetch() 
    FetchCategories()
    window.scrollTo(0, 0)
},[])







return(
    <section class="blog_area sec_pad">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 blog_sidebar_left">
                        
                            
                            { Pdata.length==0 ?
                                <div style={{width: '100%',height: '100%', minheigh:'50vh',textAlign: 'center'}}><span class="loader" style={{position:'relative',top:'50%'}}></span></div>
                            :
                            <div class="blog_single mb_50" style={{minHeight:'25vh'}}>
                            <div class="blog_content">
                            By: <span style={{fontWeight:'bold'}}>{Pdata.author!=null ? Pdata.author: 'Admin'} </span>
                                <h6>{Pdata.created_date.toDate().toString().slice(4,15)}</h6>
                                <div dangerouslySetInnerHTML={{ __html: Pdata.content }} />
                                {/* <div class="post_share">
                                    <div class="post-nam"> Share: </div>
                                    <div class="flex">
                                        <a href="#"><i class="ti-facebook"></i>Facebook</a>
                                        <a href="#"><i class="ti-twitter"></i>Twitter</a>
                                        <a href="#"><i class="ti-pinterest"></i>Pinterest</a>
                                    </div>
                                </div> */}
                            </div>
                            </div>
                            }
                        
                        {/* <div class="blog_post">
                            <div class="widget_title">
                                <h3 class="f_p f_size_20 t_color3">Related Post</h3>
                                <div class="border_bottom"></div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-sm-6">
                                    <div class="blog_post_item">
                                        <div class="blog_img">
                                            <img src="img/blog-grid/post_img_1.png" alt=""/>
                                        </div>
                                        <div class="blog_content">
                                            <div class="entry_post_info">
                                                <a href="#">March 14, 2020</a>
                                            </div>
                                            <a href="#">
                                                <h5 class="f_p f_size_16 f_500 t_color">Why I say old chap that.</h5>
                                            </a>
                                            <p class="f_400 mb-0">Harry bits and bleeding crikey argy-bargy...</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="blog_post_item">
                                        <div class="blog_img">
                                            <img src="img/blog-grid/post_img_2.png" alt=""/>
                                        </div>
                                        <div class="blog_content">
                                            <div class="entry_post_info">
                                                <a href="#">April 14, 2017</a>
                                            </div>
                                            <a href="#">
                                                <h5 class="f_p f_size_16 f_500 t_color">Bloke cracking goal the.</h5>
                                            </a>
                                            <p class="f_400 mb-0">Harry bits and bleeding crikey argy-bargy...</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6">
                                    <div class="blog_post_item">
                                        <div class="blog_img">
                                            <img src="img/blog-grid/post_img_3.png" alt=""/>
                                        </div>
                                        <div class="blog_content">
                                            <div class="entry_post_info">
                                                <a href="#">March 15, 2016</a>
                                            </div>
                                            <a href="#">
                                                <h5 class="f_p f_size_16 f_500 t_color">Oxford james bond.</h5>
                                            </a>
                                            <p class="f_400 mb-0">Harry bits and bleeding crikey argy-bargy...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                      
                        {/* <div class="widget_title mt_100">
                            <h3 class="f_p f_size_20 t_color3">Leave a Comment</h3>
                            <div class="border_bottom"></div>
                        </div>
                        <form class="get_quote_form row" action="#" method="post">
                            <div class="col-md-12 form-group">
                                <textarea class="form-control message" placeholder="Comment"></textarea>
                            </div>
                            <div class="col-md-6 form-group">
                                <input type="text" class="form-control" id="name" placeholder="Name"/>
                            </div>
                            <div class="col-md-6 form-group">
                                <input type="email" class="form-control" id="email" placeholder="Email"/>
                            </div>
                            <div class="col-md-12 form-group">
                                <input type="text" class="form-control" id="website" placeholder="Website (optional)"/>
                            </div>
                            <div class="col-md-12"><button class="btn btn_three btn_hover f_size_15 f_500"
                                    type="submit">Post Comment</button></div>
                        </form> */}
                    </div>
                    <div class="col-lg-4">
                        <div class="blog-sidebar">
                            {/* <div class="widget sidebar_widget widget_search">
                                <form action="#" class="search-form input-group">
                                    <input type="search" class="form-control widget_input" placeholder="Search"/>
                                    <button type="submit"><i class="ti-search"></i></button>
                                </form>
                            </div> */}
                            
                            <div class="widget sidebar_widget widget_categorie mt_60">
                                <div class="widget_title">
                                    <h3 class="f_p f_size_20 t_color3">Categories</h3>
                                    <div class="border_bottom"></div>
                                </div>
                                <ul class="list-unstyled">
                                {CAT.map((data, index)=>{
                                    return(
                                    <li key={index}> <button style={{background:'none',border:'none'}} onClick={(e)=>{
                                        e.preventDefault()
                                        Nav(`/Blog/${data}`)
                                    }}><span>{data}</span></button> </li>)
                                })}  
                                </ul>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>
        </section>
)

}

export default DetailedPost