import { useEffect, useState } from "react";
import "../css/Blog.css"
import { db } from "../App";
import { useNavigate,useParams } from "react-router-dom";
import { addDoc, collection, doc, getDoc,getDocs, onSnapshot, query, serverTimestamp, where } from "firebase/firestore";


const CategoryBlog =()=>{

const {category} = useParams()

const Nav = useNavigate()


const [Pdata, setPdata] =  useState([])
const [CAT,setCAT] = useState([])
const [Search,setSearch] = useState('')
const [Reference,SetReference]  = useState([])
const [reslmessage,setreslmessage]= useState('')
const [Postnotfound,setPostnotfound] = useState(false)



const FETCHPosts =async()=>{
const REF = collection(db,'boltzmannlabs-posts')
const q = query(REF, where("categories", "array-contains", `${category}`)); 
await onSnapshot(q,(POSTE) => {
    const postarray= []
    if(POSTE.empty){
        setPostnotfound(true)
    }
    POSTE.forEach(Posts => {
     if(Posts.data().published) {
            postarray.push({id:Posts.id,Author:Posts.data().author ,category:Posts.data().category,title:Posts.data().title,content:Posts.data().content.match(/<img[^>]*>/), date:Posts.data().created_date.toDate()}) 
            }
    
    })
    postarray.sort(function(a,b){
        return b.date-a.date
        })
    setPdata(postarray)
    SetReference(postarray)
    })
}
const FetchCategories = async ()=>{

    const Ref = collection(db,'boltzmannlabs-categories')
    const CATARR =[]
    const CATS = await getDocs(Ref)
    CATS.forEach((data)=>{
       CATARR.push(data.data().title)
    })
  setCAT(CATARR)
}




const SearchFun =(e)=>{
    e.preventDefault()
    const data = Reference
    const refineddata =data.filter((data)=>{ return data.title.toLowerCase().includes(Search)})
    if (refineddata.length==0){
        setPostnotfound(true)
    }
    setPdata(refineddata)
    setreslmessage(Search)
    }


    const clearSearch=()=>{
        setSearch('')
        setPdata(Reference)
        setreslmessage('')
    }



console.log(Pdata)

useEffect(()=>{
    document.title = 'Boltzmann | Blog'
    FETCHPosts()
    FetchCategories()
    window.scrollTo(0, 0)
},[category])
  
    return (
        <div style={{marginTop:'20px'}}>
    
    <section class="blog_area sec_pad">
   
            <div class="container">
            <p  style={{fontWeight:'bold', color:'black'}}>Category  : <span style={{color:'#501f84'}}>{category}</span> </p>
           {
                (reslmessage!='' ) && 
                <div style={{marginBottom:'10px'}}>
                <span style={{color:'black'}}>Search results for {reslmessage}</span>
                <button onClick={clearSearch} style={{background:'none',border:'none'}}><i class="fa fa-times" aria-hidden="true"/></button>
                </div>
            }
                <div class="row">
                    <div class="col-lg-8">
                   
                        
                            
                {
                    Pdata.length==0 ?
                     Postnotfound ?
                    <p style={{fontSize:'20px', color:'red',padding:'10px'}}> No posts Found</p> 
                    : 
                    
                    <div style={{width: '100%',height: '100%', minheigh:'50vh',textAlign: 'center'}}><span class="loader" style={{position:'relative',top:'50%'}}></span></div>
                    :
                    <div class="row" id="blog_masonry">
                    {
                                Pdata.map((data,index)=>{
                              
                                return(
                              
                              <div class="col-lg-6 col-sm-6">
                                <div class="blog_grid_item mb-30" key={index}>
                                
                                    <div class="blog_img">
                                    {data.content!=null ?
                                      <div style={{minHeight:'200px'}}  dangerouslySetInnerHTML={{ __html: data.content }} />
                                      :<img style={{minHeight:'200px'}} src={require('../newimages/blank.jpg')} />
                                    }
                                    
                                    </div>
                                    <div class="blog_content">
                                        <div class="entry_post_info">
                                        <div class="entry_post_info">
                                            By: <span>{data.Author!=null ? data.Author: 'Admin'} /</span>
                                            <span>{data.date.toString().slice(4,15)}</span>
                                        </div>
                                        </div>
                                        <a onClick={(e)=>{e.preventDefault();Nav(`/POST/${data.id}`)}}>
                                            <h5 class="f_p f_size_20 f_500 t_color mb_20">{data.title}
                                            </h5>
                                        </a>
                                    </div>
                                
                                </div>
                                 </div>
                                ) }
                                )
                    }
                    </div>
                                
                     
                                }
                                
                           
                           
                            {/* <ul class="col-lg-12 list-unstyled page-numbers shop_page_number text-left mt_30">
                                <li><span aria-current="page" class="page-numbers current">1</span></li>
                                <li><a class="page-numbers" href="#">2</a></li>
                                <li><a class="next page-numbers" href="#"><i class="ti-arrow-right"></i></a></li>
                            </ul> */}
                        
                    </div>
                    <div class="col-lg-4">
                        <div class="blog-sidebar">
                            <div class="widget sidebar_widget widget_search">
                                
                            <div style={{borderStyle:'solid', display:'flex',justifyContent: 'space-between', borderRadius:'10px'}}>
                                    <input style={{border:'none',background:'none',outline: 'none',width: '90%',paddingLeft:'10px'}} onChange={(e)=>{
                                    setSearch(e.target.value.toLowerCase())}} value={Search}></input>
                                    <button style={Search==''?{display:'none'}:{border:'none',background:'none'}}
                                    onClick={clearSearch} ><i class="fa fa-times" aria-hidden="true"></i></button>
                                    <button style={{border:'none',background:'none'}} onClick={SearchFun}>
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="widget sidebar_widget widget_categorie mt_60">
                                <div class="widget_title">
                                    <h3 class="f_p f_size_20 t_color3">Categories</h3>
                                    <div class="border_bottom"></div>
                                </div>
                                </div>
                                <ul class="list-unstyled">
                                {CAT.map((data,index)=>{
                                    return(
                                    <li key={index}> <button style={{background:'none',border:'none'}} onClick={(e)=>{
                                        e.preventDefault()
                                        Nav(`/Blog/${data}`)
                                    }}><span>{data}</span></button> </li>)
                                    
                                })}  
                                </ul>
                            {/* <div class="widget sidebar_widget widget_tag_cloud mt_60">
                                <div class="widget_title">
                                    <h3 class="f_p f_size_20 t_color3">Tags</h3>
                                    <div class="border_bottom"></div>
                                </div>
                                <div class="post-tags">
                                    <a href="#">SaasLand</a>
                                    <a href="#">Web Design</a>
                                    <a href="#">Saas</a>
                                    <a href="#">Cooling System</a>
                                    <a href="#">Corporate</a>
                                    <a href="#">Software</a>
                                    <a href="#">Landing</a>
                                    <a href="#">Wheels</a>
                                </div> 
                            </div> */}
                        </div>
                    </div>

                </div>
            </div>
        </section>
        </div>
      );


    



}
export default CategoryBlog