import {useState,useEffect} from 'react'
import "../css/Blog.css"
import "../css/homeblog.css"
import { db } from "../App";
import { useNavigate } from "react-router-dom";
import protein from "../newimages/protein.png"
import { addDoc, collection, doc, getDoc,getDocs, onSnapshot, query, serverTimestamp, where } from "firebase/firestore";









const Homepage = ()=>{



  const Nav = useNavigate()

const [Pdata, setPdata] =  useState([])
const [CAT,setCAT] = useState([])
const [Search,setSearch] = useState('')
const [Reference,SetReference]  = useState([])
const [reslmessage,setreslmessage]= useState('')





const responsiveStyles = {
  '@media screen and (max-width: 768px)': {
    flexDirection: 'column',
    marginLeft: 0,
  },
};




  const FETCHPosts =async()=>{
    const Ref = collection(db,'boltzmannlabs-posts')
    
    await onSnapshot(Ref,(POSTE) => {
        const postarray= []
        POSTE.forEach(Posts => {
            if(Posts.data().published) {
                postarray.push({id:Posts.id,category:Posts.data().category,title:Posts.data().title,content:Posts.data().content.match(/<img[^>]*>/), date:Posts.data().created_date.toDate(), Author:Posts.data().author}) 
                }
        
        })
        console.log(postarray)
        postarray.sort(function(a,b){
        return b.date-a.date
        })
        setPdata(postarray)
        
        })
    }








useEffect(()=>{
    document.title = 'Boltzmann | Home'
    window.scrollTo(0, 0)
    FETCHPosts()
},[])


return(
    
    
    
    <div >

  
    <header id="home" class="header">

      <div class="row justify-content-md-center" style={{backgroundColor: "#501F84", marginBottom:'100px'}}>
      
        <div class="col-lg-8 pt-8">
          <div class="row justify-content-md-center">
            <div class="col-lg-8">
              <div class="card p-2 head-card">
              <h1 class="slideTitle main-title"><span class="bh1">Boltzmann Labs</span> <br/>Leading the way in AI Drug Discovery</h1>
              </div>
            </div>
            <div class="col-md-8 col-xs-12">
              <div class="card hero-card mb-2">
                <div class="card-body">
                  <div class="card-text">
                    Designed to become a nexus for Drug Discovery <br/>by creating potential
                    tools and ecosystems
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-8 col-xs-12">
              <div class="card hero-card mb-2">
                <div class="card-body">
                  <div class="card-text">
                    Research accelerated in all stages of Drug Discovery <br/>using the latest
                    technology and data-driven approaches.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8 col-xs-12 pb-8">
              <div class="card hero-card mb-2">
                <div class="card-body">
                  <div class="card-text">
                    Be it small molecules, peptides, or proteins Boltzmann <br/>has solutions for
                    all kinds of drug discovery problems.
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-4 heroimage">
          <img src={protein} class="rotate"/>

          <img src={protein} class="rotate2"/>
          
        </div>
      </div>
    </header>
  



  <main class="main-content" >

    <section class="bg-gray work-flow-head"  style={{marginBottom:'none'}}>
      <div class="ag-timeline_title-box d-flex justify-content-center">
        <br/>
        <h3 style={{color: "white",fontWeight: "bold",fontSize: "35px"}} id="work-flow">Optimised drug discovery integrated
          end-to-end</h3>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="main-timeline">
              <div class="timeline">
                <div class="timeline-content">
                  <div class="circle" style={{backgroundImage:`url(${require('../newimages/dna.png')})`,backgroundSize: 'cover'}}><span
                      class="homebox"/></div>
                  <div class="content">
                    <h3 class="year d-flex justify-content-center">TARGET IDENTIFICATION</h3>
                    <div class="w300"></div>
                    <div class="icon"><span></span></div>
                  </div>
                </div>
              </div>
              <div class="timeline mobileBolt">
                <div class="content">
                  <div class="row d-flex justify-content-center">

                    <h3 class="title h4 fleft">BOLTBIO</h3>
                  </div>

                  <div class="row mt-3">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry1.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">Multi-Omics Analysis</h3><br/><br/>
                      <p class="left">To gain insights, preprocess and analyse the big data in human biology</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry2.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">Systems & Network Biology</h3><br/><br/>
                      <p class="left">Use existing or new interaction information and gene expressions to create a
                        knowledge graph.</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry3.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">Target Identification</h3><br/><br/>
                      <p class="left">Analyze Graph Neural Networks to identify, and prioritize biomarkers.</p>
                    </div>
                  </div>
                </div>

              </div>
              <div class="timeline">
                <div class="timeline-content">
                  <div class="circle"
                    style={{backgroundImage:`url(${require('../newimages/icons/moltest1.jpg')})`,backgroundSize: "cover"}}><span
                      class="homebox"/></div>
                  <div class="content">

                    <h3 class="year d-flex justify-content-center">DRUG DESIGN</h3>
                    <div class="w300"></div>

                    {/* <!-- <span class="year">BoltPro</span> -->
                    <!-- <h3 class="title h4">DRUG DESIGN</h3> --> */}
                    <div class="icon"><span></span></div>

                  </div>
                </div>
              </div>
              <div class="timeline">
                <div class="content cmt">
                  <div class="row d-flex justify-content-center">

                    <h3 class="title h4 fleft">BOLTCHEM & BOLTPRO</h3>
                  </div>

                  <div class="row mt-3">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry4.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">QSAR like models</h3><br/><br/>
                      <p class="left">Build QSAR like property models to predict bioactivity, physicochemical or
                        pharmacokinetic properties using Machine Learning and Deep
                        Learning.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry5.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">Molecule design</h3><br/><br/>
                      <p class="left">Molecules designed to fit the desired chemical space using AI.</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry6.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">Screening</h3><br/><br/>
                      <p class="left">Generated molecules or commercially available molecules are screened virtually as
                        per the hypothesis with multiple layers of screens.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages//icons/biochemistry7.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">Molecule Optimization</h3><br/><br/>
                      <p class="left">Drug-like molecules optimized with AI flows to improve efficacy, affinity, and
                        selectivity.</p>
                    </div>
                  </div>
                </div>

              </div>
              <div class="timeline">
                <div class="timeline-content">
                  <div class="circle"
                    style={{backgroundImage:`url(${require('../newimages/synth.jpg')})`,backgroundSize: "cover",backgroundPositionX:"-17px"}}>
                    <span class="homebox"/>
                  </div>
                  <div class="content">
                    <h3 class="year d-flex justify-content-center">SYNTHESIS PLANNING</h3>
                    <div class="w300"></div>

                    {/* <!-- <h3 class="title 4">SYNTHESIS PLANNING</h3> --> */}
                    <div class="icon"><span></span></div>

                  </div>
                </div>
              </div>
              <div class="timeline">
                <div class="content cmt">
                  <div class="row d-flex justify-content-center">

                    <h3 class="title h4 fleft">REBOLT</h3>
                  </div>
                  <div class="row mt-3">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry8.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class=" col-10">
                      <h3 class="fleft">Retrosynthetic Analysis</h3><br/><br/>
                      <p class="left">Prediction of synthetic routes by breaking down complex molecules into simpler
                        commercially available reactants.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biohemistry9.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">Chemical Catalogue</h3><br/><br/>
                      <p class="left">Find the links of simpler commercially available starting materials in the
                        synthetic routes with purchases facilitated.</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biocchemistry10.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">Synthesis</h3><br/><br/>
                      <p class="left">Generate analogues easily with the synthetic paths.</p>
                    </div>
                  </div>
                </div>

              </div>
              <div class="timeline">
                <div class="timeline-content">
                  <div class="circle"
                    style={{backgroundImage:`url(${require('../newimages/med.jpeg')})`,backgroundSize: "cover",backgroundPosition:"center"}}>
                    <span class="homebox"/>
                  </div>
                  <div class="content">
                    <h3 class="year d-flex justify-content-center mt-n5">MED CHEM & BIOLOGY CAPABILITIES</h3>
                    <div class="w300"></div>

                    <div class="icon icon2"><span></span></div>
                    {/* <!-- <h3 class="title 4">SYNTHESIS PLANNING</h3> -->
                    <!-- <div class="icon"><span></span></div> --> */}

                  </div>
                </div>
              </div>
              <div class="timeline">
                <div class="content cmt">
                  {/* <!-- <div class="row">

                    <h3 class="title h4 fleft ml-9">BOLTCHEM</h3>
                  </div> --> */}
                  <div class="row mt-3">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry11.png")} style={{maxHeight:"100px"}} />
</div>
<div class=" col-10">
                      <h3 class="fleft">Compound Synthesis</h3><br/><br/>
                      <p class="left">Efficient and scalable synthesis of new drug
                        compounds using automated technology.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry12.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">High throughput binding studies through SPR</h3><br/><br/>
                      <p class="left">binding efficacy analysis of compounds through high
                        throughput surface plasmon resonance (SPR) studies</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry1.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">Validation in Cell-free and Cell-Based mechanistic
                        studies and Cell human disease models</h3><br/><br/>
                      <p class="left">compounds shortlisted through SPR will be validated in cell-free
                        assays and cell-based disease models.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry2.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">ADME screening</h3><br/><br/>
                      <p class="left">Assessment of Absorption, Distribution, Metabolism,
                        and Excretion (ADME) properties of lead candidates.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry3.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">In-vitro Tox profiling</h3><br/><br/>
                      <p class="left">Assessment of compound toxicity
                        in relevant cell-based models.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry4.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">In-vivo efficacy</h3><br/><br/>
                      <p class="left">Evaluation of in-vivo efficacy of the compound
                        in disease-specific animal models.</p>
                    </div>
                  </div>
                </div>

              </div>
              <div class="timeline">
                <div class="timeline-content">
                  <div class="circle"
                    style={{backgroundImage: `url(${require('../newimages/chem-mol3-min.jpg')})`,backgroundSize:"cover"}}><span
                      class="homebox"></span></div>
                  <div class="content">
                    <h3 class="year d-flex justify-content-center">CLINICAL TRIAL</h3>
                    {/* <!-- <h3 class="title h4">CLINICAL TRIAL</h3> --> */}
                    <div class="w300"></div>

                    <div class="icon"><span></span></div>
                  </div>
                </div>
              </div>
              <div class="timeline mb-10">
                <div class="content cmt">
                  <div class="row d-flex justify-content-center">

                    <h3 class="title h4 fleft">CLINBOLT</h3>
                  </div>
                  <div class="row mt-3">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry11.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">Patient Simulation</h3><br/><br/>
                      <p class="left">ClinBolt works with digital twins, which replicate real-world objects based on
                        previous inputs of data and also deals with generating the sequence of occurrences.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <img src={require("../newimages/icons/biochemistry12.png")} style={{maxHeight:"100px"}}/>
                    </div>
                    <div class="col-10">
                      <h3 class="fleft">Trial Design</h3><br/><br/>
                      <p class="left">ClinBolt simplifies trial design through patient analysis, outcome prediction,
                        criteria development, site selection and recruitment.</p>
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>


      </div>
    </section>
    <section style={{textAlign:'center', backgroundColor:'#501F84', overflow:'hidden'}}>
       <h1 style={{color:'white',padding:'20px'}}>Blog</h1>
      <div class="homeblog" style={{display:'flex',justifyContent: 'space-around', padding:'10px', marginLeft:'10px',...responsiveStyles}}>
      {    
                            Pdata.slice(0,2).map((data,index)=>{
                                
                            return(
                          <div class="post" key={index}>
                            <div class="blog_grid_item mb-30"style={{maxHeight:'82%',minHeight:'82%',overflow:'hidden'}} >
                            
                                <div class="blog_img" >
                                {data.content!=null ?
                                  <div  style={{minWidth:'100%'}} dangerouslySetInnerHTML={{ __html: data.content }} />
                                  :<img style={{aspectRatio:1.5,minWidth:'100%'}} src={require('../newimages/boltzman-web-new.png')} />
                                }
                                </div>
                                <div class="blog_content">
                                    <div class="entry_post_info">
                                        By: <span>{data.Author!=null ? data.Author: 'Admin'} /</span>
                                        <span>{data.date.toString().slice(4,15)}</span>
                                    </div>
                                    <a onClick={(e)=>{e.preventDefault();Nav(`/POST/${data.id}`)}}>
                                        <h5 class="f_p f_size_20 f_500 t_color mb_20">{data.title}
                                        </h5>
                                    </a>
                                </div>
                            
                            </div>
                             </div>
                             ) }
                            )}


                           <div class="post">
                            <div class="blog_grid_item mb-30" style={{maxHeight:'82%',minHeight:'82%',overflow:'hidden'}}>
                                  
                                    <div class="blog_img">
                                        <img style={{filter:"blur(3px)"}} src={require("../newimages/chem-mol4-min.jpg")} alt=""/>
                                    </div>
                                    <div style={{position:'relative'}}>
                                    <button onClick={()=>{Nav('/blog')}} style={{border:'none',background:'none', position:'absolute',left:'25%',zIndex:1}}><span style={{fontSize:'20px', fontWeight:'bold'}}>View More ...</span></button></div>
                                    <div class="blog_content" style={{filter:"blur(3px)"}}>
                                        <div class="entry_post_info">
                                            By: <a href="#">Admin</a>
                                            <a href="#">March 14, 2020</a>
                                        </div>
                                        <a href="#">
                                            <h5 class="f_p f_size_20 f_500 t_color mb_20">Victoria sponge horse play.
                                            </h5>
                                        </a>
                                    </div>
                                  </div>
                            </div> 
        </div>                     
    </section>
    
  </main>
 

  </div>
)
}

export default Homepage;